import { CardDetail, CardDetailLoading, ReactBarcode, ReactQRCode } from '../../../common'
import Loading from './Loading'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { ReactComponent as QRIcon } from '../../../assets/images/qrcode-icon.svg'
import { ReactComponent as BarcodeIcon } from '../../../assets/images/barcode-icon.svg'
import { DeliveryGroup, Props } from './CouponsLotusDetails.model'
import { CHANNEL_TYPE, COUPON_MODE, DELIVERY_TYPE } from '../../../utils/constant'
import { ModalConfirmCopyCode } from './Modal'
import { Transition } from 'react-transition-group'
import { addGAEvent } from '../../../utils/addGoogleAnalytic'
import { isComingSoon } from '../../../utils/status'

const CouponsLotusDetails: React.FC<Props> = ({ loading, coupon, successMessage, nodeRef, isShowingSelectedCoupon, durationTransition }) => {
  const [couponMode, setCouponMode] = useState<COUPON_MODE>(COUPON_MODE.BARCODE)
  const code = coupon?.couponCode
  const [showSuccessMessage, setShowSuccessMessage] = useState<string>(successMessage || '')
  const [isShowModal, setIsShowModal] = useState(false)
  let deliveryGroup: DeliveryGroup = { isOnline: false, isOffline: false, delivery: { immediately: [], nextDay: [], inStore: [] } }

  if (coupon?.channel) {
    coupon.channel.forEach((value: string) => {
      const channel = value.toLocaleLowerCase()
      if (channel === CHANNEL_TYPE.ONLINE) deliveryGroup.isOnline = true
      if (channel === CHANNEL_TYPE.OFFLINE) deliveryGroup.isOffline = true
    })
  }
  if (deliveryGroup.isOnline && coupon?.deliveryMethod && coupon?.expiryDate) {
    coupon.deliveryMethod.forEach((value: string) => {
      const delivery = value.toLocaleUpperCase()
      if (DELIVERY_TYPE.IMMEDIATELY.includes(delivery)) deliveryGroup.delivery.immediately.push(delivery)
      if (DELIVERY_TYPE.NEXT_DAY.includes(delivery)) deliveryGroup.delivery.nextDay.push(delivery)
      if (DELIVERY_TYPE.IN_STORE.includes(delivery)) deliveryGroup.delivery.inStore.push(delivery)
    })
  }

  const copyTextToClipboard = (text: string) => {
    addGAEvent({
      event: 'copy_coupon_code',
      coupon_section: coupon?.couponName,
      coupon_type: coupon?.category,
      coupon_scope: coupon?.couponType
    })
    const input = document.createElement('input')
    input.value = text
    input.setAttribute('readonly', 'readonly')
    document.body.appendChild(input)

    input.select()
    input.setSelectionRange(0, input.value.length)
    document.execCommand('copy')
    input.remove()
    setIsShowModal(!isShowModal)
  }

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage('')
        localStorage?.setItem('successMessage', '')
      }, 3000)
    }
    if (!isShowingSelectedCoupon) {
      setTimeout(() => {
        setCouponMode(COUPON_MODE.BARCODE)
      }, 500)
    }
  }, [showSuccessMessage, isShowingSelectedCoupon, setCouponMode])

  if (isShowingSelectedCoupon && loading) {
    return <Loading />
  }

  return (
    <Transition
      nodeRef={nodeRef}
      in={isShowingSelectedCoupon}
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      {(state: string) => (
        <div
          id="coupons-lotus-details-page"
          ref={nodeRef}
          className={classNames(
            'bg-white fixed overscroll-y-contain top-0 bottom-0 left-0 right-0 z-[60] duration-' +
              durationTransition +
              ' transition-all translate-x-full',
            {
              'overflow-y-auto': !isShowModal,
            },
            {
              '!translate-x-0': state === 'entering' || state === 'entered',
            }
          )}
        >
          {
          loading ? (
            <CardDetailLoading idElement="coupons-lotus-card-loading" />
          ) : (
            <CardDetail
              idElement="coupon-details-card-detail"
              image={coupon?.media?.url}
              name={coupon?.couponName}
              startDate={coupon?.startDate}
              expiryDate={coupon?.expiryDate}
              description={coupon?.description}
              condition={coupon?.condition}
              channel={coupon?.channel}
              deliveryGroup={deliveryGroup}
              redemptionStartDate={
                isComingSoon(coupon.startDate) ? coupon.startDate : ""
              }
            >
              {isComingSoon(coupon.startDate) ? (
                  <div className='flex flex-row bg-[#FCF2D3] p-[8px] items-center rounded-lg'>
                    <div className='w-[28px] mr-[6px]'>
                      <img
                        data-testid='card-image'
                        src='/icons/circle-right.svg'
                        className='w-[24px]'
                        alt='card'
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = '/icons/coupon-image-placeholder.svg'
                          currentTarget.className = 'w-[24px]'
                        }}
                      />
                    </div>
                    
                    <p className='text-[#424242] text-sm'>คูปองยังไม่ถึงวันที่กำหนดให้ใช้</p>
                  </div>
                ) : (
                <></>
              )}
              {!isComingSoon(coupon.startDate) && deliveryGroup.isOnline ? (
                <div className={'flex justify-between items-center rounded-lg bg-white-smoke ' + (deliveryGroup.isOffline ? 'mb-6' : '')}>
                  <p className='p-3 text-base text-[#6B6B6B] font-bold font-lotuss'>
                    {code}
                  </p>
                  <button
                    type='button'
                    className='p-3 bg-mint-green text-white text-lg font-bold font-lotuss rounded-lg'
                    onClick={() => copyTextToClipboard(code)}>คัดลอก   
                  </button>
                </div>
              ) : (
                <></>
              )}

              {deliveryGroup.isOffline ? (
                <div className='flex justify-center items-center mb-0.5 rounded-t-lg bg-white-smoke'>
                  {!code && <p className='text-sm font-lotuss text-gray-inactive my-5'>Couldn't load the Barcode/QRCode</p>}
                  {couponMode === COUPON_MODE.BARCODE && code && 
                    <ReactBarcode className='px-[38px] h-[112px]' value={code} options={{
                      background: '#F3F3F3',
                      lineColor: '#424242',
                      height: 76,
                      fontSize: 21.7687, // there is some scaling 73.6% of the defined value (73.6% of 21.7687px = 16px)
                      font: 'Lotuss Smart HL',
                      textMargin: 6,
                      margin: 0,
                      fontOptions: '500'
                    }} />
                  }
                  {couponMode === COUPON_MODE.QRCODE && code &&
                    <div className='h-[112px] flex items-center'>
                      <ReactQRCode code={code} options={{ margin: 0, color: { light: '#F3F3F3' } }} />
                    </div>
                  }
                </div>
              ) : (
                <></>
              )}

              {deliveryGroup.isOffline ? (
                <div className='flex justify-center items-center rounded-b-lg bg-white-smoke'>
                  <button
                    className={classNames('flex-1 h-11 leading-7 text-lg font-lotuss font-bold', {
                      'text-mint-green fill-mint-green': couponMode === COUPON_MODE.BARCODE,
                      'text-gray-secondary': couponMode === COUPON_MODE.QRCODE,
                    })}
                    onClick={() => {
                      setCouponMode(COUPON_MODE.BARCODE)
                      addGAEvent({
                        event: 'scan_coupon_option',
                        coupon_section: coupon?.couponName,
                        coupon_type: coupon?.category,
                        coupon_scope: coupon?.couponType,
                        coupon_kind: coupon?.channel?.join(','),
                        scan_option: 'barcode'
                      })
                    }}
                  >
                    <BarcodeIcon
                      className={classNames('inline-block mr-2', {
                        '[&>path]:fill-mint-green': couponMode === COUPON_MODE.BARCODE
                      })}
                    /> บาร์โค้ด
                  </button>

                  <div className='w-px h-7 border-r-1 border-[#E0E0E0]'></div>

                  <button
                    className={classNames('flex-1 h-11 leading-7 text-lg font-lotuss font-bold', {
                      'text-mint-green': couponMode === COUPON_MODE.QRCODE,
                      'text-gray-secondary': couponMode === COUPON_MODE.BARCODE
                    })}
                    onClick={() => {
                      setCouponMode(COUPON_MODE.QRCODE)
                      addGAEvent({
                        event: 'scan_coupon_option',
                        coupon_section: coupon?.couponName,
                        coupon_type: coupon?.category,
                        coupon_scope: coupon?.couponType,
                        coupon_kind: coupon?.channel?.join(','),
                        scan_option: 'QR code'
                      })
                    }}
                  >
                    <QRIcon
                      className={classNames('inline-block mr-2', {
                        '[&>path]:fill-mint-green': couponMode === COUPON_MODE.QRCODE
                      })}
                    /> คิวอาร์โค้ด
                  </button>
                </div>
              ) : (
                <></>
              )}
            </CardDetail>
          )}
          <ModalConfirmCopyCode
            idElement="coupon-details-modal-confirm-copy-code"
            isShowModal={isShowModal}
            setIsShowModal={setIsShowModal}
          />          
        </div>
      )}
    </Transition>
  )
}

export default CouponsLotusDetails;
