import { useEffect, useRef, useState, forwardRef, useImperativeHandle, Ref, useCallback } from 'react'
import dayjs from 'dayjs'
import { CouponCard } from '../../../common'
import { getLotusCoupons } from '../../../services/my-coupon-bff'
import { Coupon } from '../../../services/my-coupon-bff/types'
import { getParam } from '../../../utils/params'
import { DATE_FORMAT, getDate } from '../../../utils/date'
import { Error as ErrorPremiumList } from '../../../common'
import EmptyCoupons from './EmptyCoupons'
import { ErrorResponse } from '../../../utils/reponseType'
import Loading from './Loading'
import classNames from 'classnames'
import { COUPONS_LOTUS_TYPE, COUPON_CATEGORY } from '../../../utils/constant'
import uuid4 from 'uuid4'
import { addGAEvent } from '../../../utils/addGoogleAnalytic'
import { isComingSoon } from '../../../utils/status'

interface CouponCategoryMapping {
  [key: string]: Coupon[]
}

export interface CouponLotusListForwardRefObject {
  handleScrollToCategorySection: (index: number) => void
}

let previousScrollY: number = 0
let isDisabledUpdateCategory = true

export interface Props {
  loading: boolean
  point: number
  setSelectedCoupon: (selectedCoupon: Coupon | undefined) => void
  setIsShowingSelectedCoupon: (value: boolean) => void
  activeCouponType: string
  setHideCouponType: (isHideCoupon: boolean) => void
  setHideCategoryCoupon: (isHideCategory: boolean) => void
  isHideCouponMenu: boolean
  setActiveCategory: (activeCategory: number) => void
  handleCategoryFilterScollTo: (index: number) => void
  couponDetailId?: string
}

let couponRequestId = ''

const CouponsLotusList = forwardRef<CouponLotusListForwardRefObject, Props>(
  (
    {
      loading,
      point,
      setSelectedCoupon,
      setIsShowingSelectedCoupon,
      activeCouponType,
      setHideCouponType,
      setHideCategoryCoupon,
      isHideCouponMenu,
      setActiveCategory,
      handleCategoryFilterScollTo,
      couponDetailId
    },
    ref: Ref<CouponLotusListForwardRefObject>
  ) => {
    const [isLoading, setLoading] = useState<boolean>(loading)
    const [isError, setIsError] = useState<boolean>(false)
    const [isCouponEmpty, setIsCouponEmpty] = useState<boolean>(false)
    const [error, setError] = useState<ErrorResponse>()
    const [coupons, setCoupons] = useState<CouponCategoryMapping>({})
    const [activeCouponId, setActiveCouponId] = useState<string>('')
    const [voucherSection, setVoucherSection] = useState<HTMLElement | null>(null)
    const [categorySection, setCategorySection] = useState<HTMLElement | null>(null)
    const [productSection, setProductSection] = useState<HTMLElement | null>(null)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const refreshCouponLotus = localStorage.getItem('refreshCouponLotus')
    const paddingOffset = 78 // Offset 76px padding on parent with buffer 2 px

    useImperativeHandle(ref, () => ({
      handleScrollToCategorySection,
    }))

    const getSortedByStartDateAsc = (coupons: Coupon[]) => {
      return coupons.sort((a, b) => {
        return dayjs(a.startDate).isAfter(dayjs(b.startDate))
          ? 1
          : -1
      })
    }
   
    const getSortedByExpiryDate = (coupons: Coupon[]) => {
      return  coupons.sort((a, b) => {
        return (a.expiryDate && !b.expiryDate) ||
          dayjs(a.expiryDate).isBefore(dayjs(b.expiryDate))
          ? -1
          : 1
      })
    }
    const groupAndSortCoupons = (coupons: Coupon[]): Coupon[] => {
      const readyToUseCoupons: Coupon[] = []
      const comingSoonCoupons: Coupon[] = []
      let sortedCoupons: Coupon[] = []
    
      coupons.forEach((offer) => {
        if (isComingSoon(offer.startDate)) {
          readyToUseCoupons.push(offer)
        } else {
          comingSoonCoupons.push(offer)
        }
      })

      if (readyToUseCoupons.length) {
        const sortedByExpiryDate = getSortedByExpiryDate(comingSoonCoupons)
        const sortedByName = getSortedByNameAsc(sortedByExpiryDate)
    
        sortedCoupons = [...sortedCoupons, ...sortedByName]
      }
      if (comingSoonCoupons.length) {
        const sortedByStartDate = getSortedByStartDateAsc(readyToUseCoupons)
        sortedCoupons = [...sortedCoupons, ...sortedByStartDate]
      }

      return sortedCoupons
    }

    async function fetchCouponsLotus() {
      const requestId = uuid4()

      couponRequestId = requestId
      localStorage.removeItem('refreshCouponLotus')

      try {
        setLoading(true)
        let token: string = getParam('token') || localStorage?.getItem('token') || ''
        const response = await getLotusCoupons(token)

        if (response && requestId === couponRequestId) {
          const result = response as Coupon[] | []
          const couponCategoryMapping: CouponCategoryMapping = {}
          const activeFilterType =
            activeCouponType && activeCouponType !== ''
              ? activeCouponType
              : COUPONS_LOTUS_TYPE.OFFLINE

          if (result.length > 0) {
            const filterResult = result.filter((coupon) => {
              if (
                activeFilterType === COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU &&
                coupon.couponType === activeFilterType
              )
                return true
              if (
                activeFilterType !== COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU &&
                coupon.channel.includes(activeFilterType.toLocaleLowerCase())
              )
                return true

              return false
            })

            filterResult.forEach((data) => {
              if (!couponCategoryMapping[data.category]) couponCategoryMapping[data.category] = []

              couponCategoryMapping[data.category].push(data)
            })

            Object.keys(couponCategoryMapping).forEach((category) => {
              const coupons = couponCategoryMapping[category]

              if (!coupons || coupons.length === 0) return; // Skip empty categories
          
              couponCategoryMapping[category] = groupAndSortCoupons(coupons)
            })

            setIsCouponEmpty(!filterResult.length)
          } else {
            setIsCouponEmpty(true)
            setHideCategoryCoupon(true)
          }
          setCoupons(couponCategoryMapping)
          setLoading(false)
          setIsError(false)
        }
      } catch (e) {
        setError(e as ErrorResponse)
        setLoading(false)
        setIsError(true)
        setHideCategoryCoupon(true)
      }
    }

    useEffect(() => {
      if (!coupons) {
        fetchCouponsLotus()
      }
    }, [coupons])

    useEffect(() => {
      if (refreshCouponLotus) {
        fetchCouponsLotus()
      }
    }, [refreshCouponLotus])

    useEffect(() => {
      if (couponDetailId) setActiveCouponId(couponDetailId)
    }, [couponDetailId, setActiveCouponId])

    useEffect(() => {
      const handleParentEvent = (event: MessageEvent) => {
        if (event.data === 'iframe:navigate_back') {
          // activeCouponId === "" mean we are in list page
          const action = getParam('action')
          if (activeCouponId === '' || action) {
            if (window.parent) window.parent.postMessage('parent:navigate_back', '*')
          } else {
            // else we are in detail page
            setActiveCouponId('')
            setIsShowingSelectedCoupon(false)
          }
        }
      }

      if (window) {
        window.addEventListener('message', handleParentEvent)
      }

      return () => {
        window.removeEventListener('message', handleParentEvent)
      }
    }, [activeCouponId])

    useEffect(() => {
      if (window.parent) {
        window.parent.postMessage('iframe:ready', '*')
      }
    }, [])

    useEffect(() => {
      fetchCouponsLotus()

      const filterCouponSection = document.querySelector(getActiveCouponType())

      filterCouponSection?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }, [activeCouponType])

    const handleScrollToCategorySection = (activeCategoryIndex: number) => {
      const targetSection = getSectionRef(activeCategoryIndex)

      if (scrollContainerRef.current) {
        handleCategoryFilterScollTo(activeCategoryIndex)
        setActiveCategory(activeCategoryIndex)
        isDisabledUpdateCategory = true
        scrollContainerRef.current.scrollTo({
          top: (targetSection?.offsetTop ?? 0) - paddingOffset + 5,
          behavior: 'smooth',
        })
      }
    }

    const handleOpenCouponDetail = (coupon: Coupon) => {
      setActiveCouponId(coupon.couponId)
      setSelectedCoupon(coupon)
      setIsShowingSelectedCoupon(true)
      addGAEvent({
        event: 'mycoupon_caปrd',
        coupon_section: coupon?.couponName,
        coupon_type: coupon?.category,
        coupon_scope: coupon?.couponType,
        coupon_kind: coupon?.channel?.join(',')
      })
    }

    const handleScroll = (event: { currentTarget: { scrollTop: any } }) => {
      const { scrollTop } = event.currentTarget
      const scroll: 'up' | 'down' = scrollTop >= previousScrollY ? 'up' : 'down'

      if (scrollTop > 25) {
        setHideCouponType(true)
        setHideCategoryCoupon(false)
      } else {
        setHideCategoryCoupon(true)
        setHideCouponType(false)
      }

      let paddingOffset = 76
      if (scroll === 'up') {
        paddingOffset += 5
      } else if (scroll === 'down') {
        paddingOffset -= 5
      }

      if (!isDisabledUpdateCategory) {
        if (productSection && productSection?.offsetTop - paddingOffset <= scrollTop) {
          handleCategoryFilterScollTo(2)
          setActiveCategory(2)
        } else if (categorySection && categorySection?.offsetTop - paddingOffset <= scrollTop) {
          handleCategoryFilterScollTo(1)
          setActiveCategory(1)
        } else if (voucherSection && voucherSection?.offsetTop - paddingOffset <= scrollTop) {
          handleCategoryFilterScollTo(0)
          setActiveCategory(0)
        }
      }

      previousScrollY = scrollTop

      // to detect the scroll is stopped
      setTimeout(() => {
        if (scrollTop === previousScrollY) {
          isDisabledUpdateCategory = false
        }
      }, 100)
    }

    const getActiveCouponType = () => {
      if (activeCouponType === COUPONS_LOTUS_TYPE.ONLINE) {
        return `#${COUPONS_LOTUS_TYPE.ONLINE}`
      }
      if (activeCouponType === COUPONS_LOTUS_TYPE.OFFLINE) {
        return `#${COUPONS_LOTUS_TYPE.OFFLINE}`
      }
      if (activeCouponType === COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU) {
        return `#${COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU}`
      }

      return `#${COUPONS_LOTUS_TYPE.ONLINE}`
    }

    const getSectionRef = (activeCategoryIndex: number) => {
      if (activeCategoryIndex === 0) return voucherSection
      if (activeCategoryIndex === 1) return categorySection
      if (activeCategoryIndex === 2) return productSection

      return null
    }

    const getSectionRefSetter = (category: COUPON_CATEGORY) => {
      if (category === COUPON_CATEGORY.VOUCHER) return setVoucherSection
      if (category === COUPON_CATEGORY.CATEGORY) return setCategorySection
      if (category === COUPON_CATEGORY.PRODUCT) return setProductSection

      return null
    }

    const getSortedByNameAsc = (coupons: Coupon[]) => {
      if (coupons) {
        return coupons.sort((a, b) => {
          const firstCharacterCouponNameA = a?.couponName[0]
          const firstCharacterCouponNameB = b?.couponName[0]
          if (/\d/.test(firstCharacterCouponNameA) && /\d/.test(firstCharacterCouponNameB)) {
            return parseInt(firstCharacterCouponNameA) - parseInt(firstCharacterCouponNameB)
          }

          return firstCharacterCouponNameA.localeCompare(firstCharacterCouponNameB, 'en', { sensitivity: 'base', ignorePunctuation: true })
        })
      }
      return coupons
    }

    if (isLoading || loading) {
      return <Loading />
    }

    if (isError) {
      if (error?.code === 401) {
        return <Loading />
      } else {
        return (
          <div className="flex-1 flex justify-center item-center mb-[125px]">
            <ErrorPremiumList
              idElement="coupons-error"
              onClick={fetchCouponsLotus}
              status={error?.code || 500}
              refCode={error?.refCode || ''}
            />
          </div>
        )
      }
    }

    if (isCouponEmpty) {
      return <EmptyCoupons />
    }

    return (
      <div
        ref={scrollContainerRef}
        id="coupons-list"
        data-testid="coupons-lotus-list-page"
        onScroll={handleScroll}
        className={classNames('flex flex-col w-screen overflow-y-auto')}
      >
        {[COUPON_CATEGORY.VOUCHER, COUPON_CATEGORY.CATEGORY, COUPON_CATEGORY.PRODUCT].map(
          (category) => {
            const hasMultipleCategories =
              [
                coupons[COUPON_CATEGORY.VOUCHER]?.length,
                coupons[COUPON_CATEGORY.CATEGORY]?.length,
                coupons[COUPON_CATEGORY.PRODUCT]?.length,
              ].filter(Boolean).length > 1
            const isLastCategory =
              (category === COUPON_CATEGORY.CATEGORY &&
                (!coupons[COUPON_CATEGORY.PRODUCT] ||
                  coupons[COUPON_CATEGORY.PRODUCT].length === 0)) ||
              category === COUPON_CATEGORY.PRODUCT

            return (
              coupons[category] && (
                <div
                  id={`${category}Section`}
                  ref={getSectionRefSetter(category)}
                  key={category}
                  className={classNames({
                    'min-h-[calc(100vh-76px)]':
                      hasMultipleCategories && isLastCategory && isHideCouponMenu,
                    'min-h-[calc(100vh-125px)]':
                      hasMultipleCategories && isLastCategory && !isHideCouponMenu,
                  })}
                >
                  {(coupons[category] || []).map((coupon, index) => {
                    const expiryDate = coupon.expiryDate ? getDate(coupon.expiryDate, DATE_FORMAT) : ''
                    let categoryText = ''
                    if (category === COUPON_CATEGORY.VOUCHER) categoryText = 'ส่วนลดเงินสด'
                    if (category === COUPON_CATEGORY.CATEGORY) categoryText = 'ส่วนลดตามหมวดหมู่'
                    if (category === COUPON_CATEGORY.PRODUCT) categoryText = 'ส่วนลดสินค้า'

                    return (
                      <div key={coupon.couponId} id={`coupon-${index}`}>
                        {index === 0 && (
                          <label
                            id="category-title"
                            className={classNames(
                              'mb-[12px] ml-[12px] text-lg h-[20px] font-[700] text-gray-primary',
                              {
                                'mt-[24px]': !isHideCouponMenu,
                                'mt-[12px]': isHideCouponMenu,
                              }
                            )}
                          >
                            {categoryText}
                          </label>
                        )}
                        <div
                          id={`coupon-${index}`}
                          data-testid={`coupon-component-${coupon.couponId}`}
                          onClick={() => handleOpenCouponDetail(coupon)}
                          className={classNames(
                            'flex items-center bg-coupon bg-no-repeat bg-[length:100%_100%] mb-[2px] px-[12px] h-[156px]',
                            {
                              'mb-[32px]': index === (coupons[category] || []).length - 1,
                            }
                          )}
                        >
                          <CouponCard
                            idElement={index}
                            id={coupon.couponId}
                            title={coupon.couponName}
                            description={coupon.description}
                            expiryDate={expiryDate}
                            startDate={coupon.startDate}
                            image={coupon.media.url}
                            deliveryMethod={coupon.deliveryMethod}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            )
          }
        )}
      </div>
    )
  }
)

export default CouponsLotusList
