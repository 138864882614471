import * as React from 'react'
import classNames from 'classnames'
import './Coupon.css'
import { mappingImageDefault } from '../../utils/mapper'
import { isComingSoon } from '../../utils/status'
import { getDate } from '../../utils/date'

export type Props = {
  id: string
  title: string
  description: string
  expiryDate: string
  startDate: string
  image: string
  loading?: boolean
  deliveryMethod: any
  idElement: number
}

const removeHtmlAndNewlines = (text: string) => {
  const withoutTags = text.replace(/<\/?[^>]+(>|$)/g, '')
  const withoutNewlines = withoutTags.replace(/\r?\n/g, '')
  return withoutNewlines
}

const CouponCard: React.FC<Props> = ({
  id,
  title,
  description,
  expiryDate,
  startDate,
  image,
  loading,
  deliveryMethod,
  idElement,
}) => {
  const img = mappingImageDefault(image)
  const disable = isComingSoon(startDate)

  return (
    <div
      key={id}
      id={`coupon-component-${idElement}`}
      className={classNames('flex flex-row px-[12px] pb-[8px] pt-[2px] rounded-xl',
        {
          "opacity-50": loading,
        }
      )}
    >
      <div
        id={`coupon-image-${idElement}`}
        className={classNames(
          "rounded-lg max-w-26 min-h-[110px] bg-gray-empty flex items-center justify-center mr-2",
          {
            "opacity-50": disable,
          }
        )}
      >
        {image && image !== '' ? (
          <img
            id={`image-${idElement}`}
            data-testid="card-image"
            src={img}
            className="rounded-lg w-26 max-h-26"
            alt="card"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/icons/coupon-image-placeholder.svg'
              currentTarget.className = 'rounded-lg w-26'
            }}
          />
        ) : (
          <img
            src="/icons/coupon-image-placeholder.svg"
            alt="img-placeholder"
            className="rounded-lg w-26"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/icons/coupon-image-placeholder.svg'
            }}
          />
        )}
      </div>
      <div
        id={`coupon-detail-component-${idElement}`}
        className="flex flex-col w-[calc(100vw-166px)] bg-blue font-lotuss"
      >
        <div id="card-detail" data-testid="card-detail" className="h-full mb-2">
          <p
            id={`coupon-title-${idElement}`}
            data-testid="card-company"
            className={classNames(
              'w3/4 leading-6 font-bold text-ellipsis whitespace-nowrap overflow-hidden text-[16px] text-gray-primary',
              {
                'opacity-50': disable,
              }
            )}
          >
            {removeHtmlAndNewlines(title)}
          </p>
          <p
            id={`coupon-detail-${idElement}`}
            data-testid="card-title"
            className={classNames(
              'leading-[20px] tracking-wide font-medium text-ellipsis whitespace-nowrap overflow-hidden text-[14px] text-[#6B6B6B]',
              {
                'opacity-50': disable,
              }
            )}
          >
            {removeHtmlAndNewlines(description)}
          </p>
        </div>
        <div className="bottom-0" id={`coupon-date-component-${idElement}`}>
          <p
            className={classNames(
              'rounded-lg bg-gradient-to-l from-smoke to-white-smoke w-1/3 h-[12px] mb-2',
              { hidden: !loading }
            )}
          />
          {isComingSoon(startDate) && 
            <p className="text-[14px] text-danger z-[1000000]">
              {`เริ่มใช้ ${getDate(startDate, 'DD MMM BBBB, HH:mm')}`}
            </p>
          }
          {expiryDate && (
            <p
              id={`coupon-date-${idElement}`}
              className="text-[14px] font-medium text-gray-inactive font-lotuss tracking-wide leading-[20px]"
            >
              {`หมดเขต ${expiryDate}`}
            </p>
          )}
          <div className="flex flex-row" id={`coupon-delivery-method-${idElement}`}>
            {(deliveryMethod.includes('HD') || deliveryMethod.includes('HDSD')) && (
              <p id={`delivery-method-ส่งทันที`} className="h-[18px] text-[12px] mt-[4px] px-[4px] rounded-md text-[#424242] whitespace-nowrap mr-1 bg-[#F8FBD8]">
                ส่งทันที
              </p>
            )}
            {deliveryMethod.includes('HDND') && (
              <p id={`delivery-method-ส่งวันถัดไป`} className="h-[18px] text-[12px] mt-[4px] px-[4px] rounded-md text-[#424242] whitespace-nowrap mr-1 bg-[#E1FFFE]">
                ส่งวันถัดไป
              </p>
            )}
            {(deliveryMethod.includes('CC') ||
              deliveryMethod.includes('CCSD') ||
              deliveryMethod.includes('CCND')) && (
              <p id={`delivery-method-รับที่ร้าน`} className="h-[18px] text-[12px] mt-[4px] px-[4px] rounded-md text-[#424242] whitespace-nowrap mr-1 bg-[#E9F9F8]">
                รับที่ร้าน
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CouponCard
